@tailwind base;
@tailwind components;
@tailwind utilities;

.scroll-container::-webkit-scrollbar {
  display: none;
}

.font-size-90p {
  font-size: calc(90% * 1rem); /* Adjust 1rem based on your base font size */
}

.reveal {
  opacity: 0; /* Initially hidden */
  transform: scale(0.5);
  animation: circle-reveal 1s ease-out forwards;
}

.reveal-delay-1 {
  animation-delay: 0s;
}

.reveal-delay-2 {
  animation-delay: 0.5s;
}

.reveal-delay-3 {
  animation-delay: 1s;
}

@keyframes circle-reveal {
  0% {
    opacity: 0;
    clip-path: circle(0% at 50% 50%);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    clip-path: circle(100% at 50% 50%);
    transform: scale(1);
  }
}

/* Pagination */

.paginationContainer {
  display: flex;
  list-style-type: none;
  justify-content: flex-start;
  padding: 10px;
  color: #ffffff;
  column-gap: 15px;
  /* flex-wrap: wrap;  */
}

.paginationLink {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ffffff;
  cursor: pointer;
  text-align: center;
  min-width: 40px; /* Ensure a minimum width for each link */
}

.paginationDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.paginationActive {
  background-color: #ffffff33;
  color: white;
  width: 25px;
  display: flex;
  justify-content: center;
}

@media (max-width: 500px) {
  .paginationContainer {
    justify-content: center; /* Center pagination for smaller screens */
    column-gap: 10px; /* Reduce gap between items */
  }

  .paginationLink {
    padding: 4px 8px; /* Reduce padding for smaller screens */
    font-size: 12px; /* Adjust font size for better fit */
  }

  .paginationActive {
    width: 20px; /* Reduce width of the active page indicator */
  }
}

@media (max-width: 370px) {
  .paginationContainer {
    column-gap: 5px; /* Further reduce the gap */
    padding: 8px; /* Adjust padding */
  }

  .paginationLink {
    padding: 3px 6px; /* Smaller padding for very small screens */
    font-size: 10px; /* Further adjust font size */
  }

  .paginationActive {
    width: 18px; /* Make the active page smaller */
  }
}

/*  */

/* Initially hide additional content */
.hidden-content {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Show additional content when hovering over the container */
.relative:hover .hidden-content {
  opacity: 1;
  visibility: visible;
}

/* Ensure correct visibility based on screen size */
.hidden-lg {
  display: none;
}

/* Show hidden content on large screens and above */
@media (min-width: 1024px) {
  .hidden-lg {
    display: block;
  }
}

/* Hide content for screens larger than large (lg) */
.hidden-sm {
  display: block;
}

/* Show content only on smaller screens */
@media (min-width: 1024px) {
  .hidden-sm {
    display: none;
  }
}

/*  */
.no-scroll {
  overflow: hidden;
  height: 100%;
  position: fixed; /* This helps to lock the position */
  width: 100%; /* Make sure it covers the full width */
}

/*  */

/* Hide scrollbars but allow scrolling */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/*  */
html {
  scroll-behavior: smooth;
}
